<template>
  <div id="Content">
    <div class="content-inner">
      <confirm-modal
        ref="confirmModal"
        cancelText="Never mind! I’ll stay"
        confirmText="Yes, cancel subscription"
        popUpTitleMsg="Are you sure?"
        @confirm-method="cancelPlan"
        popUpMsg="By cancelling your guest list subscription you will lose all data at the end of your term. Your subscription will remain until the final day of your billing period though, so you do have time to back it up."
      />
      <div class="subscription-container">
        <div class="row">
          <div class="col flex half center">
            <h1>My Subscription</h1>
          </div>
        </div>

        <div class="active-plan row" v-if="isActive">
          <div class="col flex half center">
            <p>
              <b>Active Plan:</b>
              {{ billing_details.name }}
            </p>
          </div>
        </div>
        <Card class="plan-tiles-container card">
          <div class="plan-tiles">
            <div class="plan-tile">
              <div class="plan-header">Plan Details</div>
              <div class="plan-body">
                <div class="total">
                  <span class="left">Total Credits</span>
                  <span class="right">{{ plan_credits_details.total }}</span>
                </div>
                <div class="total">
                  <span class="left">Credits Used</span>
                  <span class="right">{{ plan_credits_details.used }}</span>
                </div>
                <div class="total">
                  <span class="left">Available Credits</span>
                  <span class="right">
                    {{ plan_credits_details.available }}
                  </span>
                </div>
              </div>
              <div class="plan-footer"></div>
            </div>
            <div class="plan-tile">
              <div class="plan-header">Plan Features</div>
              <div class="plan-body">
                <div class="features" v-if="isActive">
                  <div
                    class="feature"
                    v-for="(feature, index) in features"
                    :key="index"
                  >
                    <font-awesome-icon icon="check" class="icon" />
                    {{ feature }}
                  </div>
                </div>
                <div class="features" v-else>
                  <div class="feature">
                    <font-awesome-icon icon="check" class="icon" />
                    N/A
                  </div>
                </div>
              </div>
              <div class="plan-footer"></div>
            </div>
            <div class="plan-tile">
              <div class="plan-header">Billing & Payment</div>
              <div class="plan-body">
                <div class="total">
                  <div class="left">Price</div>
                  <div class="right">${{ billing_details.monthly_price }}</div>
                </div>
                <div class="total">
                  <div class="left">Billing Period</div>
                  <div class="right">{{ billing_details.period }}</div>
                </div>
                <div class="total">
                  <div class="left">Status</div>
                  <div class="right">
                    {{ billing_details.status }}
                  </div>
                </div>
                <div class="total">
                  <div class="left">{{ billing_details.renew_text }}</div>
                  <div class="right">
                    {{ formatDate(billing_details.renew_date) }}
                  </div>
                </div>
              </div>
              <div class="plan-footer" v-if="isActive">
                <span class="secondary">
                  Payement Method
                </span>
                <p>
                  {{ billing_details.card_type }} ending in
                  {{ billing_details.last_4_digits }} Expires
                  {{ billing_details.exp_month }}/{{ billing_details.exp_year }}
                </p>
              </div>
            </div>
          </div>
          <div class="row buttons" v-if="canCancelAndChange">
            <div class="col half">
              <FormulateInput
                type="button"
                name="Change Plan"
                @click="changePlan"
              />
            </div>
            <div class="col half">
              <FormulateInput
                type="button"
                name="Cancel Plan"
                @click="cancelModal"
              />
            </div>
          </div>
          <div class="row buttons" v-if="canResume">
            <div class="col full">
              <FormulateInput
                type="button"
                name="Resume Plan"
                @click="resumePlan"
              />
            </div>
          </div>
          <div class="row buttons" v-if="!isActive">
            <div class="col full">
              <FormulateInput
                type="button"
                name="Resubscribe"
                @click="resumePlan"
              />
            </div>
          </div>
        </Card>
        <div class="row">
          <div class="col half">
            <div class="invoices">
              <h4>Invoices Feed</h4>
              <div
                class="invoice-container"
                v-for="(invoice, index) in invoices"
                :key="index"
              >
                <div class="divider"></div>
                <div class="invoice">
                  <div class="status">Paid</div>
                  <div class="date">{{ formatDate(invoice.created_at) }}</div>
                  <div class="amount">${{ invoice.amount }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col half">
            <div class="faqs">
              <h4>Frequently Asked Questions</h4>
              <p>
                Can't find the answer you're looking for?
              </p>
              <p>
                Read the
                <a href="">Quick Start Guide</a>
                or visit the
                <a href="">Help Desk</a>
                for more.
              </p>

              <div
                class="faq-cont"
                v-for="(ques, index) in BQuestions"
                :key="index"
                :class="{ expand: ques.open }"
                @click="ques.open = !ques.open"
              >
                <div class="question">
                  <p>
                    {{ ques.q }}
                  </p>
                  <font-awesome-icon icon="angle-down" />
                </div>
                <div class="ans" v-if="ques.open">
                  <p class="answer">
                    {{ ques.ans }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faCheck,
  faCheckCircle,
  faLink
} from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";
import ConfirmModal from "../../../components/modals/ConfirmModal.vue";
library.add(faCheck);

export default {
  name: "active-subscription",
  components: {
    FontAwesomeIcon,
    ConfirmModal
  },
  data() {
    return {
      plan_credits_details: {
        total: 5000,
        used: 2000,
        available: 3000,
        last_updated: "01/04/23"
      },
      billing_details: {
        amount: 50.0,
        period: "Monthly",
        renew_date: "Jun 15, 2023"
      },
      BQuestions: [
        {
          q: "Is my package price per event or for all of my events??",
          ans:
            "Your guest list package covers any event under the specified brand. If you add another brand (ie: you own 2 nightclubs) you would need to order another package for the second club.",
          open: false
        },
        {
          q: "How are fees calculated on my customer’s purchases?",
          ans:
            "Unless otherwise arranged with your INCREDEVENT rep, the only fees that we charge your customers are our costs, which work out to an average of 5% if the total. In some cases, it may be less.",
          open: false
        },
        {
          q: "What happens when I am nearing the capacity of my subscription?",
          ans:
            "You will get a notification on the site and in the INCREDEVENT app when you’ve reached 75% capacity and 100% capacity. Please make sure you download our app so you can stay up to date.",
          open: false
        },
        {
          q: "How does the AI work?",
          ans:
            "It takes roughly 3 months for our system to learn valuable information about your guests. Once it has enough data, you will see different options appear in our Email Event Options under “Suggested Buyer Groups”. Examples of suggestions our AI may make include birthday groups, buyers that haven’t been to your club in 3 months, etc.",
          open: false
        },
        {
          q: "Why do my bottle, food, and section prices keep changing?",
          ans:
            "What is likely happening is you’re using our Previous feature. If you click on a previous item and then change the price but keep the same name, the price will change system wide. If you’d like to have the same bottle in the system under a different price you’ll simply want to alter the name of the item. ",
          open: false
        },
        {
          q: "Why can’t I upload my menu?",
          ans:
            "There are challenges to uploading a menu. Our development team is working on the best way to do this and we hope to release that feature in the near future.",
          open: false
        }
      ],
      features: [
        "Access to all features",
        "Email only",
        "Upgrades to SMS available",
        "4% Additional transaction fee",
        "Top tier support"
      ],
      invoices: [],
      cancelMessage:
        " Thank you for using our service. Your Subscription has been scheduled to cancel. You'll be able to continue using guest list until the end of billing period."
    };
  },
  computed: {
    brandUrl() {
      return this.$route.params.subdomain;
    },
    canResume() {
      if (this.billing_details.status === "Expiring") {
        return true;
      }
    },
    isActive() {
      if (
        this.billing_details.status === "Active" ||
        this.billing_details.status === "Expiring" ||
        this.billing_details.status === "Trial" ||
        this.billing_details.status === "Downgraded"
      ) {
        return true;
      }
    },
    canCancelAndChange() {
      if (
        this.billing_details.status === "Active" ||
        this.billing_details.status === "Downgraded"
      ) {
        return true;
      }
    }
  },
  methods: {
    formatDate(date) {
      return dayjs(date).format("MMM DD, YYYY");
    },
    changePlan() {
      this.$router.push("change-plan");
    },
    cancelModal() {
      this.$refs.confirmModal.open();
    },
    resumePlan() {
      this.$axios
        .post(`/subscriptions/resume`, { brandUrl: this.brandUrl })
        .then(({ data }) => {
          window.location.reload();

          this.$toast.success(
            `Your subscription for this brand has been resumed.`
          );
        });
    },
    cancelPlan() {
      this.$axios
        .post(`/subscriptions/cancel/`, { brandUrl: this.brandUrl })
        .then(({ data }) => {
          window.location.reload();

          this.$toast.success(
            `Your subscription for this brand has been cancelled.`
          );
        });
    }
  },
  created() {
    this.$axios
      .get(`/subscriptions/get-guestlist-subscription/${this.brandUrl}`)
      .then(({ data }) => {
        this.billing_details = {
          ...data.invoices[0],
          period: "Monthly",
          renew_date: data.plan.end_date,
          name: data.plan.name,
          status: data.plan.status,
          renew_text: data.plan.renew_text,
          monthly_price: data.plan.price
        };

        this.plan_credits_details = {
          total: data.invoices[0].total_credits,
          available: data.invoices[0].available_credits,
          used: data.invoices[0].used_credits
        };

        this.invoices = data.invoices;
      });
  },
  mounted() {}
};
</script>

<style lang="less" scoped>
.plan-tiles {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 16px;
  .plan-tile {
    padding: 16px;
    border-radius: 8px;
    background: var(--content-base);

    .plan-header {
      font-size: 20px;
      margin-bottom: 16px;
      font-weight: 600;
    }

    .total {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;

      .left {
        color: var(--text-secondary);
      }
    }

    .features {
      .feature {
        color: var(--text-secondary);
        margin-bottom: 8px;
      }

      .icon {
        color: var(--primary);
        border: 1px solid;
        padding: 3px;
        border-radius: 50%;
        margin-right: 8px;
      }
    }
    .plan-footer {
      margin-top: 32px;
      text-align: center;
      span.secondary {
        font-size: 14px;
        color: var(--text-secondary);
      }
    }
  }
}

.plan-tiles-container {
  margin-bottom: 16px;
  .buttons {
    margin-top: 16px !important;
  }
  .formulate-input {
    width: 100%;
    margin-bottom: 0;

    ::v-deep button {
      width: 100%;
      border-radius: 8px;
      background: var(--content-light);
    }
  }
}

.invoices {
  padding: 16px;
  border-radius: 8px;
  background: var(--content-light);

  h4 {
    color: white;
    font-weight: 600;
    margin: 0;
    margin-bottom: 16px;
  }

  .invoice {
    display: flex;
    justify-content: space-between;

    .amount {
      min-width: 80px;
      text-align: right;
    }
    .divider {
      width: 100%;
    }
  }
}

.faqs {
  text-align: center;
  p {
    margin: 0;
  }
  .faq-cont {
    padding: 16px;
    font-size: 15px;
    border-radius: 8px;
    margin: 16px 0;
    cursor: pointer;
    transition: all 0.4s linear;
    background: var(--content-light);

    .question {
      display: flex;
      justify-content: space-between;
      align-items: center;

      svg {
        font-size: 25px;
        transition: all 0.4s;
        margin-left: 10px;
        color: var(--text-secondary);
      }
    }
    p {
      margin: 0;
      font-size: 14px;
      font-weight: 600;
    }
    // font-weight:600;
    p.answer {
      text-align: left;
      margin: 16px 0;
      font-size: 15px;
      font-weight: 400;
    }

    &.expand {
      // border: 0px;
      // background: var(--dashboard-tiles);

      svg {
        transform: rotate(180deg);
        transition: all 0.4s;
      }
    }

    @media screen and (max-width: 600px) {
      padding: 5%;

      h3 {
        font-size: 15px;
      }
      p {
        font-size: 12px;
        line-height: 180%;
      }
    }
  }
}
</style>